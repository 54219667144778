import { defineStore } from 'pinia'
import { computed, ComputedRef, ref, Ref, watchEffect } from 'vue'
import { useAPIState } from '@/composables/useApiState'
import dayjs from 'dayjs'
import pinpointClient from '@/clients/pinpointClient'
import { useApplicationStore } from '../application'
import { useLogger } from '@/composables/logger'
import { connectArecibo, DEALER_STATUS_CHANGE, registerArecibo } from '@/composables/websocket'
import dealerStatuses from '@/constants/dealerStatuses'

import {
    SPREAD_FEES_SLUG,
    TDealer,
    TDealerConfigs,
    TStatistics,
    TContactTitles,
    TAllContacts,
} from '@/stores/dealer/dealerTypes'
import { BANK_ACCOUNT_STATUS, BankAccount, BankAccountResponse } from '@/models/Dealer/BankAccount'

export const DEALER_ADDRESS_TYPE = {
    PHYSICAL: 1,
    MAILING: 3,
}

export interface DealerStore {
    dealer: Ref<TDealer>
    statistics: Ref<TStatistics>
    requestDealerId: Ref<number | null>
    dealerConfigs: Ref<TDealerConfigs>
    dealerIsSuspended: ComputedRef<boolean>
    bankAccount: Ref<BankAccount>
    isPrivileged: Ref<boolean>
    userName: Ref<string>
    contactTitles: Ref<TContactTitles[]>
    contacts: Ref<TAllContacts>
    getStatistics: {
        execute: (delay?: number | undefined) => Promise<any>
        isReady: Ref<boolean>
    }
    cIsAccountSetup: ComputedRef<boolean>
    cIsUsuryState: ComputedRef<boolean>
    getConfigBySlug: (dealerId?: number, slug?: string) => Promise<boolean>
    saveBankingInfo: (account: BankAccount) => Promise<any>
    getBankInformation: (dealerID: number | undefined) => Promise<any>
    getContactTitles: {
        execute: (delay?: number | undefined) => Promise<any>
        isReady: Ref<boolean>
    }
    getDealerContacts: {
        execute: (delay?: number | undefined) => Promise<any>
        isReady: Ref<boolean>
    }
    getDealer: {
        execute: (delay?: number | undefined) => Promise<any>
        isReady: Ref<boolean>
    }
}

export const useDealerStore = defineStore('dealer', () => {
    const dealer: Ref<TDealer> = ref({
        id: null,
        name: null,
        phone_number: null,
        dealer_type_id: null,
        type: null,
        team_id: null,
        active: null,
        legal_name: null,
        email: null,
        dealer_track_id: null,
        routeone_id: null,
        addresses: [],
    })
    const statistics: Ref<TStatistics> = ref({})
    const bankAccount: Ref<BankAccount> = ref({
        name: '',
        routingNumber: '',
        accountNumber: '',
        paymentOptionId: '',
        status: '',
    })
    const contactTitles: Ref<TContactTitles[]> = ref([])
    const contacts: Ref<TAllContacts> = ref({
        pagination: {
            CurrentPage: 1,
            ResultsPerPage: 100,
            TotalResults: 0,
            TotalPages: 1,
        },
        data: [],
    })
    const isPrivileged: Ref<boolean> = ref(false)
    const userName: Ref<string> = ref('')

    const requestDealerId: Ref<number | null> = ref(null)
    const dealerConfigs: Ref<TDealerConfigs> = ref({
        hasSpreadFee: false,
    })

    const dealerIsSuspended: ComputedRef<boolean> = computed(
        () => dealer.value.active === dealerStatuses.SUSPENDED.toString(),
    )

    const cIsAccountSetup: ComputedRef<boolean> = computed(() => {
        return bankAccount.value.status === BANK_ACCOUNT_STATUS.VERIFIED
    })

    const getDealer = useAPIState(
        computed(() => ({
            url: `/dealer/${requestDealerId.value}`,
        })),
        dealer,
        { immediate: false },
    )
    const getStatistics = useAPIState(
        computed(() => ({
            url: `/stats/dealer-summary/${requestDealerId.value}`,
        })),
        statistics,
        { immediate: false },
    )

    const cIsUsuryState: ComputedRef<boolean> = computed(() => {
        const usuryState = ['TX', 'KY', 'NC']
        return dealer.value.addresses.some(
            (address) => address.type_id === DEALER_ADDRESS_TYPE.PHYSICAL && usuryState.includes(address.state),
        )
    })

    const getDealerContacts = useAPIState(
        computed(() => ({
            url: `/dealer/${requestDealerId.value}/contacts`,
        })),
        contacts,
        { immediate: false },
    )
    const getContactTitles = useAPIState(
        computed(() => ({
            url: `/dealer/${requestDealerId.value}/contacts/titles`,
        })),
        contactTitles,
        { immediate: false },
    )
    watchEffect(async () => {
        if (requestDealerId.value) {
            await getDealer.execute()
            await getConfigBySlug(requestDealerId.value, SPREAD_FEES_SLUG)
            await getBankInformation()
            await connectArecibo()
            registerArecibo(requestDealerId.value.toString(), 'pinpoint', (payload: any) => {
                if (payload.data?.eventType === DEALER_STATUS_CHANGE) {
                    if (payload.data?.status?.value === dealerStatuses.INACTIVE) {
                        handleDealerInactivated()
                    }
                    if (payload.data?.status?.value === dealerStatuses.SUSPENDED) {
                        handleDealerSuspended()
                    }
                }
            })
        }
    })

    function handleDealerInactivated() {
        dealer.value.active = dealerStatuses.INACTIVE.toString()
        window.location.href = `/auth/deactivated`
    }

    function handleDealerSuspended() {
        dealer.value.active = dealerStatuses.SUSPENDED.toString()
    }

    async function getConfigBySlug(dealerId?: number, slug?: string): Promise<boolean> {
        const applicationStore = useApplicationStore()
        const logger = useLogger()

        if (!dealerId) return false
        const today = dayjs()
        const formattedDate = today.format('YYYY-MM-DDTHH:mm:ss[Z]')
        try {
            const response = await pinpointClient.get(`/dealer/config/${dealerId}?slug=${slug}&date=${formattedDate}`)
            if (response && response.data) {
                dealerConfigs.value.hasSpreadFee = response.data
                return response.data
            }
            dealerConfigs.value.hasSpreadFee = false
            return false
        } catch {
            applicationStore.loanStructureLoader = false
            return false
        }
    }

    async function saveBankingInfo(account: BankAccount): Promise<any> {
        const payload = {
            dealer_id: dealer.value.id,
            bank_name: account.name,
            routing_number: account.routingNumber,
            account_number: account.accountNumber,
            type: account.paymentOptionId,
            status: BANK_ACCOUNT_STATUS.PENDING,
        }

        return pinpointClient.post('/dealer/bank/save', payload)
    }

    async function getBankInformation(dealerId: number | undefined = undefined): Promise<any> {
        try {
            const myDealerId = dealerId || dealer.value.id
            if (!myDealerId) return
            const response = await pinpointClient.get<BankAccountResponse>(`/dealer/bank/${myDealerId}`)
            if (response.data) {
                bankAccount.value.accountNumber =
                    response.data.account_number.length > 0 ? response.data.account_number.padStart(12, '*') : ''
                bankAccount.value.routingNumber = response.data.routing_number
                bankAccount.value.name = response.data.bank_name
                bankAccount.value.status = response.data.status
                bankAccount.value.id = response.data.id
                bankAccount.value.microTransactionSent = response.data?.micro_transaction_sent
                bankAccount.value.paymentOptionId = response.data.type
            }
        } catch (error) {
            useLogger().error('Error getting bank information', error)
        }
    }
    return {
        contacts,
        contactTitles,
        bankAccount,
        dealer,
        dealerIsSuspended,
        dealerConfigs,
        getStatistics,
        isPrivileged,
        requestDealerId,
        statistics,
        userName,
        cIsAccountSetup,
        cIsUsuryState,
        getConfigBySlug,
        getBankInformation,
        saveBankingInfo,
        getContactTitles,
        getDealerContacts,
        getDealer,
    } as DealerStore
})
