import axios from 'axios'
import { useLogger } from '@/composables/logger'

const logger = useLogger()

const pinpointClient = axios.create({
    baseURL: import.meta.env.VITE_URL_PINPOINT,
    withCredentials: true,
})

pinpointClient.interceptors.response.use(
    (response) => {
        return response
    },
    (error) => {
        if (!error.response) {
            logger.error(error)
            throw error
        }
        if (error?.config?.data) {
            const data = JSON.parse(error?.config?.data)
            if (data.ignoreGlobalCatch) {
                throw error
            }
        }
        let endpointDealerID
        switch (error.response.status) {
            case 401:
                if (error.response.data?.message === 'dealer is deactivated') {
                    window.location.href = `/auth/deactivated`
                    break
                }
                if (error.config.url.match(/dealer\/(\d+)/)) {
                    ;[, endpointDealerID] = error.config.url.match(/dealer\/(\d+)/)
                }
                if (endpointDealerID) {
                    window.location.href = `/dealer/${endpointDealerID}/email?callback=${window.location.pathname}`
                } else {
                    window.location.href = `/`
                }
                break
            case 404:
                break
            case 418:
                window.location.href = `/alert/maintenance`
                break
            default:
                logger.error(error, error.response)
                throw error
        }
    },
)

export default pinpointClient
