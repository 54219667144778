<template>
    <div>
        <div class="bg-slate-50 px-6 py-4">
            <h1 class="text-xl font-medium py-2 border-b mb-6 text-normal">{{ cTile }}</h1>
            <v-form v-model="isFormValid">
                <v-text-field
                    id="cy-id-verify-form-bank-name"
                    bg-color="white"
                    v-model="bankName"
                    label="Bank name"
                    outlined
                    class="mb-4"
                    :rules="[validators.required]" />
                <v-text-field
                    id="cy-id-verify-form-routing-number"
                    bg-color="white"
                    v-model="routingNumber"
                    label="Routing number"
                    outlined
                    class="mb-4"
                    :maxlength="9"
                    @update:model-value="routingNumber = $event.replace(/\D/g, '')"
                    :rules="[validators.required, validators.minLength(9, 'Min 9 digits'), validators.maxLength(9)]" />
                <v-text-field
                    id="cy-id-verify-form-confirm-routing-number"
                    bg-color="white"
                    v-model="confirmRoutingNumber"
                    label="Confirm routing number"
                    outlined
                    class="mb-4"
                    :maxlength="9"
                    @update:model-value="confirmRoutingNumber = $event.replace(/\D/g, '')"
                    :rules="[
                        validators.required,
                        validators.match(routingNumber, 'Routing numbers do not match'),
                        validators.minLength(9, 'Min 9 digits'),
                        validators.maxLength(9),
                    ]" />
                <v-text-field
                    id="cy-id-verify-form-account-number"
                    bg-color="white"
                    v-model="accountNumber"
                    label="Account number"
                    outlined
                    class="mb-4"
                    :rules="[validators.required, validators.minLength(8, 'Min 8 digits'), validators.maxLength(17)]"
                    @update:focused="onFocusAccountNumber"
                    :maxlength="18"
                    @update:model-value="accountNumber = $event.replace(/\D/g, '')" />
                <v-text-field
                    id="cy-id-verify-form-confirm-account-number"
                    bg-color="white"
                    v-model="confirmAccountNumber"
                    label="Confirm account number"
                    outlined
                    class="mb-4"
                    :maxlength="18"
                    @update:model-value="confirmAccountNumber = $event.replace(/\D/g, '')"
                    :rules="[
                        validators.required,
                        validators.match(accountNumber, 'Account numbers do not match'),
                        validators.minLength(8, 'Min 8 digits'),
                        validators.maxLength(17),
                    ]" />
                <v-select
                    id="cy-id-verify-form-payment-option"
                    v-model="paymentOption"
                    bg-color="white"
                    label="Choose payment frequency option"
                    :items="[
                        { title: 'ACH', value: 'ach' },
                        // { title: 'Wire transfer', value: 'wire', disabled: true }, // TODO: Enable when wire transfer is available
                        // { title: 'FedNow', value: 'fednow', disabled: true },      // TODO: Enable when FedNow is available
                    ]" />
            </v-form>
            <p class="text-xs text-slate-900">
                *In order to verify your bank account we will be sending a micro-transaction to your account. The amount
                will be between $0.01 and $0.15 and will say “Arivo Acceptance” in the description. Please follow the
                prompts you will receive after finalizing your account setup. Keep in mind that the Micro-Transaction
                can take up to 48 hours to fully complete
            </p>
        </div>
        <div class="flex flex-row gap-4 py-4 justify-end">
            <v-btn
                id="cy-id-verify-form-cancel-button"
                class="text-none"
                variant="plain"
                color="primary"
                :height="36"
                @click="cancel"
                >Cancel</v-btn
            >

            <v-btn
                id="cy-id-verify-form-verify-button"
                class="text-none"
                color="primary"
                :height="36"
                :disabled="!isFormValid"
                :loading="loading"
                @click="verifyAccount"
                >Verify account</v-btn
            >
        </div>
    </div>
</template>
<script lang="ts" setup>
import { useLogger } from '@/composables/logger'
import { validators } from '@/helpers/validators'
import { BANK_ACCOUNT_STATUS } from '@/models/Dealer/BankAccount'
import { useDealerStore } from '@/stores/dealer/dealer'
import { onMounted } from 'vue'
import { ComputedRef, Ref, computed, onUnmounted, ref } from 'vue'

interface IProps {
    bankName: string
    routingNumber: string
    accountNumber: string
    paymentOption: string
    bankAccountStatus: string
}

const props = defineProps<IProps>()
const emits = defineEmits(['updated-bank-information', 'cancel'])

const dealerStore = useDealerStore()
const { saveBankingInfo } = dealerStore

const bankName: Ref<string> = ref(props.bankName)
const routingNumber: Ref<string> = ref(props.routingNumber)
const confirmRoutingNumber: Ref<string> = ref('')
const accountNumber: Ref<string> = ref(props.accountNumber)
const accountNumberOriginal: Ref<string> = ref(props.accountNumber)
const confirmAccountNumber: Ref<string> = ref('')
const paymentOption: Ref<string> = ref(props.paymentOption)
const isFormValid: Ref<boolean> = ref(false)
const loading: Ref<boolean> = ref(false)

const cTile: ComputedRef<string> = computed(() => {
    return props.bankAccountStatus === BANK_ACCOUNT_STATUS.PENDING ||
        props.bankAccountStatus === BANK_ACCOUNT_STATUS.SENT
        ? 'Change your bank account?'
        : 'Change your verified bank account?'
})

function clearForm(): void {
    bankName.value = ''
    routingNumber.value = ''
    confirmRoutingNumber.value = ''
    accountNumber.value = ''
    confirmAccountNumber.value = ''
    paymentOption.value = ''
}

function cancel(): void {
    clearForm()
    if (props.bankAccountStatus === BANK_ACCOUNT_STATUS.VERIFIED) {
        return
    }
    emits('cancel')
}

function onFocusAccountNumber(isFocused: boolean): void {
    if (isFocused) {
        if (accountNumber.value.includes('*')) {
            accountNumber.value = ''
        }
    } else {
        if (accountNumber.value.length === 0) {
            accountNumber.value = accountNumberOriginal.value
        }
    }
}

async function verifyAccount(): Promise<void> {
    loading.value = true
    saveBankingInfo({
        name: bankName.value,
        routingNumber: routingNumber.value,
        accountNumber: accountNumber.value,
        paymentOptionId: paymentOption.value,
        status: BANK_ACCOUNT_STATUS.PENDING,
    })
        .then(() => {
            emits('updated-bank-information')
            clearForm()
        })
        .catch((error) => {
            useLogger().error('Error saving bank information', error)
        })
        .finally(() => {
            loading.value = false
        })
}
onUnmounted(() => {
    clearForm()
})

onMounted(() => {
    if (props.bankAccountStatus === BANK_ACCOUNT_STATUS.VERIFIED) {
        clearForm()
    }
})
</script>
