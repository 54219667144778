import pinpointClient from '@/clients/pinpointClient'
import { useLogger } from '@/composables/logger'
import { emitDealerSubmittedDeal } from '@/composables/websocket'
import { MAX_VEHICLE_AGE, MIN_LOAN_AMOUNT } from '@/constants/amounts'
import { buildDealStructurePayload, buildFinalDealStructurePayload } from '@/helpers/payloads'
import { Account } from '@/models/Account/Account'
import { Applicant } from '@/models/Applicant/Applicant'
import { Application } from '@/models/Application/Application'
import { Contract } from '@/models/Contract/Contract'
import { Deal, DealStructure, VehicleDeal } from '@/models/Deal/DealStructure'
import { Dealer } from '@/models/Dealer/Dealer'
import { Document, getApplicationDocumentsPayload, uploadDocumentPayload } from '@/models/DocumentUpload'
import { AccountNote, AccountNoteResponse } from '@/models/Notes/AccountNote'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import { ToastQueue } from '@/models/toaster/toast'
import { AxiosError, AxiosProgressEvent } from 'axios'
import dayjs from 'dayjs'
import { round } from 'lodash'
import { defineStore } from 'pinia'
import { Ref, reactive, ref } from 'vue'
import { usePolicyStore } from './policy'
import { HttpStatusCode } from '@/constants/http'

const logger = useLogger()
const DAYS_TO_EXPIRE = 31

export const useApplicationStore = defineStore('application', {
    state: () => {
        const dealer = ref<Dealer | undefined>(undefined)
        const account = ref<Account | undefined>(undefined)
        const accountNotes = ref<Array<AccountNote>>([])
        const borrower = ref<Applicant | undefined>(undefined)
        const coborrower = ref<Applicant | undefined>(undefined)
        const application = ref<Application | undefined>(undefined)
        const vehicle = ref<Vehicle | undefined>(undefined)
        const isUploadError = ref(false)
        const emailAuthenticationKey = ref<string | undefined>(undefined)
        const dealerAuthenticationKeyToken = ref<string | undefined>(undefined)
        const applicationDocuments = ref<Array<Document>>([])
        const rolledAmount = ref<boolean>(false)
        const loanStructureLoader = ref<boolean>(false)
        const toastQueue = reactive<ToastQueue[]>([])
        const maxAPR = ref<number | undefined>(undefined)
        return {
            account,
            accountNotes,
            application,
            borrower,
            coborrower,
            dealer,
            isUploadError,
            emailAuthenticationKey,
            dealerAuthenticationKeyToken,
            applicationDocuments,
            vehicle,
            rolledAmount,
            loanStructureLoader,
            toastQueue,
            maxAPR,
        }
    },

    actions: {
        async getAccount(id: number, ignoreGlobalCatch = false): Promise<void> {
            try {
                const config = {
                    data: { ignoreGlobalCatch },
                }
                const response = await pinpointClient.get(`/accounts/get/${id}`, config)
                if (response.data) {
                    this.account = response.data.account
                    this.application = response.data.application
                    this.borrower = response.data.borrower
                    this.coborrower = response.data.coborrower
                    this.dealer = response.data.dealer
                }

                const policyStore = usePolicyStore()
                await usePolicyStore().getPolicies(dayjs(this.application?.created_at || new Date()).toDate())
                await this.getMaxAPR(policyStore.policy.MAX_TERM_UNDER_MILEAGE_CONSTRAINT)
            } catch (error) {
                if (error instanceof AxiosError) {
                    logger.error(error, error.response)
                } else {
                    logger.error(error)
                }
            }
        },
        async getMaxAPR(maxTerm: number) {
            const MAX_ALLOWED_POINTS = 0.02
            const max_apr = round((this.application?.terms.rate_uncapped_buy ?? 0) + MAX_ALLOWED_POINTS, 4)
            const payload = {
                account_id: this.account?.id,
                date: this.application?.created_at,
                dealer_id: this.dealer?.id.toString(),
                deal: {
                    amount_financed: MIN_LOAN_AMOUNT,
                    state: this.dealer?.state,
                    amount_financed_max: MIN_LOAN_AMOUNT,
                    term: maxTerm,
                    buy_rate: max_apr,
                    contract_rate: max_apr,
                    max_contract_rate: max_apr,
                },
                pricing: {
                    capped_price: max_apr,
                    uncapped_price: max_apr,
                },
            }
            const response = await pinpointClient.put(`/deal/calculate`, payload)
            this.maxAPR = Math.min(max_apr, response.data.Result.deal.max_contract_rate)
        },
        async createDealerAuthenticationMagicLink(email: string, dealerID: number, callback?: string): Promise<void> {
            await pinpointClient.post('/auth/magiclink', {
                email,
                dealer_id: dealerID,
                callback,
            })
        },
        async getAccountNotes(accountId: number): Promise<void> {
            try {
                const { data } = await pinpointClient.get(`/notes/account/get/${accountId}`)
                this.accountNotes = []
                if (data) {
                    this.accountNotes = data.map((note: AccountNoteResponse): AccountNote => {
                        return {
                            id: note.id,
                            note: note.note,
                            dealerName: note.dealer_name,
                            typeId: note.type_id,
                            createdAt: note.created_at,
                        }
                    })
                }
                return data
            } catch {
                this.loanStructureLoader = false
            }
        },
        async uploadDocument(
            payload: uploadDocumentPayload,
            progressRef: Ref<number>,
            abort: AbortController,
        ): Promise<void> {
            this.isUploadError = false
            const options = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                signal: abort?.signal,
                onUploadProgress: (event: AxiosProgressEvent) => {
                    let progress: number = Math.round((event.loaded * 100) / (event?.total || 1))
                    progressRef.value = progress
                },
            }

            return pinpointClient.post(`/documents/save`, payload, options)
        },
        async getApplicationDocumentsByAccountID(payload: getApplicationDocumentsPayload): Promise<void> {
            const queryParams = {
                params: {
                    account_id: payload.accountId,
                    dealer_id: payload.dealerId || this.dealer?.id,
                },
            }
            return pinpointClient.get(`/documents/get`, queryParams).then((data) => {
                if (data) {
                    this.applicationDocuments = data.data || []
                }
            })
        },
        async deleteDocument(documentId: number): Promise<void> {
            const params = {
                dealer_id: this.dealer?.id,
            }
            return pinpointClient.delete(`/documents/${documentId}`, { params })
        },
        async getVehicle(vin: string) {
            if (!this.account) {
                return
            }

            const response = await pinpointClient.get(`/accounts/${this.account.id}/get/vehicle/${vin}`)
            this.vehicle = response.data.vehicle
            return response.data.vehicle
        },
        async submitFinalDealStructure(finalDealStructure: ReturnType<typeof buildFinalDealStructurePayload>) {
            try {
                await pinpointClient.post(`/submitdeal`, finalDealStructure)
            } catch (error: any) {
                if (error?.response?.data?.message.includes('vehicle not in inventory')) {
                    throw new Error('vehicle not found in inventory')
                }
                if (error?.response?.status === HttpStatusCode.UNPROCESSABLE_ENTITY) {
                    throw new Error('deal structure not valid')
                }

                throw new Error('error while submitting deal')
            }

            emitDealerSubmittedDeal(String(finalDealStructure?.account_id))
        },
        async calculateDealStructure(deal: Deal, vehicle: VehicleDeal): Promise<DealStructure | undefined> {
            if (!this.borrower || !this.application || !this.dealer) return
            try {
                const payload = buildDealStructurePayload(deal, vehicle, this.application, this.dealer, this.borrower)
                const { data } = await pinpointClient.put(`/deal/calculate`, payload)
                return data.Result
            } catch {
                this.loanStructureLoader = false
            }
        },
        async calculateAmountFinanced(deal: Deal, vehicle: VehicleDeal): Promise<DealStructure | undefined> {
            if (!this.borrower || !this.application || !this.dealer) return
            try {
                const payload = buildDealStructurePayload(deal, vehicle, this.application, this.dealer, this.borrower)
                const { data } = await pinpointClient.put(`/deal/amount-financed`, payload)
                return data.Result
            } catch {
                this.loanStructureLoader = false
            }
        },
        queueToast(toast: ToastQueue): void {
            this.toastQueue.push(toast)
        },
        removeToastFromQueue(toast: ToastQueue): void {
            const index = this.toastQueue.indexOf(toast)
            if (index > -1) {
                this.toastQueue.splice(index, 1)
            }
        },
        getToastFromQueue(view: string): ToastQueue | undefined {
            return this.toastQueue.find((toast) => toast.view === view)
        },
    },

    getters: {
        terms: (state) => {
            return state.application?.terms
        },
        fees: (state) => {
            return state.application?.fees
        },
        customer_max_payment: function (state): number {
            if (!state.application || !state.application.terms) {
                logger.error('Tried to access state before loading account')
                return 0
            }
            if (
                state.application.terms.payment_max_override > 0 &&
                state.application.terms.payment_max_override < state.application.terms.payment_max
            ) {
                return state.application.terms.payment_max_override
            }
            return state.application.terms.payment_max
        },
        application_status: function (state): string {
            if (!state.account) {
                logger.error('Tried to access state before loading account')
                return 'N/A'
            }
            return state.account.status.status.toUpperCase()
        },
        apr: function (state): number {
            if (!state.application) {
                logger.error('Tried to access state before loading application')
                return 0
            }
            const apr: number = state.application.terms.rate_contract
                ? Math.round(state.application.terms.rate_contract * 10000) / 100
                : 0
            const max = 0
            return apr < state.application.terms.rate_capped_buy
                ? state.application.terms.rate_capped_buy
                : apr > max && max != 0
                ? max
                : apr
        },
        showAPRDisclaimer: (state) => {
            const statesToShow = ['TX', 'KY', 'NC']
            return state.dealer?.state && statesToShow.includes(state.dealer?.state)
        },
        getApplicationExpDate: (state) => {
            return dayjs(state.account?.created_at)
                .add(DAYS_TO_EXPIRE, 'days')
                .set('hour', 0)
                .set('minute', 0)
                .set('second', 0)
                .toDate()
        },
        isApplicationExpired(): boolean {
            const today = dayjs()
            return today.isAfter(this.getApplicationExpDate)
        },
        isApplicationReadOnly(): boolean {
            return this.application_status === 'FUNDING'
        },
        minYearAllowedToAddVehicle: (state) => {
            if (state.application && state.application.created_at) {
                return dayjs(state.application.created_at).subtract(MAX_VEHICLE_AGE, 'year').year()
            }
            return dayjs().year() - MAX_VEHICLE_AGE
        },
    },
})
