import { Toast } from '@/models/toaster/toast'
import { Contract } from '@/models/Contract/Contract'
import pinpointClient from '@/clients/pinpointClient'
import { inject } from 'vue'

export const useContract = () => {
    const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}

    function calculateTotalFees(contract: Contract) {
        const acquisitionFee: number =
            contract.disbursement.acquisition_fee_override || contract.disbursement.acquisition_fee

        return (
            contract.disbursement.processing_fee +
            contract.disbursement.multiple_bk +
            acquisitionFee +
            contract.disbursement.ch_13_bk +
            contract.disbursement.limited_term_id_fee +
            contract.disbursement.technological_convenience_fee || 0
        )
    }

    async function getContractByAccountID(accountId: number): Promise<Contract | undefined> {
        const { data } = await pinpointClient.get(`/contracts/account/${accountId}`)
        if (data) {
            return data
        }
        popToastLocal({
            title: 'Contract Not Found',
            message: 'We ran into a problem while trying to get your contract information.',
            timer: 5000,
            location: 'top-center',
        })
        return
    }

    return { calculateTotalFees, getContractByAccountID }
}
