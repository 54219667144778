<template>
    <v-toolbar color="transparent">
        <v-toolbar-title class="!ml-0 text-vuetify-text-normal">Accounts</v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
            <menu-sort
                data-testid="funding-sort-menu"
                :sort-menu-items="computedOptions"
                :selected="sortBy"
                @emit-sort="onSortChange"></menu-sort>
        </v-toolbar-items>
    </v-toolbar>
    <div class="grid grid-cols-12 h-full mb-4 overflow-auto">
        <aside class="col-span-6 sm:col-span-4 lg:col-span-3 xl:col-span-2 relative">
            <div
                v-if="isLoadingAccountList"
                class="absolute z-10 flex justify-center align-center h-full w-full !bg-vuetify-background !bg-opacity-50">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <account-list
                :account-list="accountList"
                @selected="onAccountSelected($event)"
                :model-value="selectedAccountStatus"
                @update:modelValue="onAccountStatusChange" />
        </aside>
        <main
            class="bg-white relative w-full h-full g min-w-fit col-span-2 sm:col-span-8 lg:col-span-9 xl:col-span-10 relative">
            <div
                v-if="isDataLoading"
                class="absolute z-10 flex justify-center align-center h-full w-full !bg-vuetify-background !bg-opacity-50">
                <v-progress-circular indeterminate color="primary" />
            </div>
            <div v-if="accountList.length" class="px-4 h-full">
                <account-info
                    v-if="selectedAccount"
                    class="border-b border-slate-900 rounded-none sticky top-0 z-10 mb-8"
                    v-bind="getAccountInfo"
                    :show-share-button="false" />
                <div class="flex flex-grow gap-x-12">
                    <div class="max-w-[249px] bg-white flex flex-col gap-y-4">
                        <VehicleCard :info="info" class="pl-2" />
                        <Disbursement
                            :amount-financed="deal.DBamountFinanced"
                            :amount-financed-max="deal.DBamountFinancedMax"
                            :acquisition-fees="deal.DBacquisitionFees"
                            variant="flat"
                            v-model:dealer-flat="deal.DBdealerFlat"
                            v-model:total-disbursement="deal.DBtotalDisbursement"
                            :short-fund-amount="deal.DBShortFundAmount"
                            :spread-fee="deal.DBspreadFee"
                            :has-spread-fee="dealerConfigs.hasSpreadFee"
                            :is-account-page="true"
                            :apr="deal.DBApr"
                            :max-apr="deal.DBMaxApr" />
                    </div>
                    <div v-if="selectedAccount" class="bg-white flex-grow">
                        <div class="flex w-full items-center gap-x-14">
                            <funding-stats
                                v-if="isFundingView"
                                :time-in-funding="computedTimeInStatus"
                                :averageFundingMinutes="statistics.avg_funding_time_minutes_all_time"
                                :nationalFundingMinutes="11"
                                :fundingExpirationDate="computedExpiresAT" />
                            <booked-status
                                v-else
                                :time-to-approval-minutes="selectedAccount.decisionedDate"
                                :time-in-funding-minutes="computedTimeInStatus"
                                :time-in-deposit-minutes="15"
                                :booked-datetime="selectedAccount.statusDate" />
                            <div class="w-full">
                                <ad-campaign variant="horizontal" />
                            </div>
                        </div>
                        <div class="flex flex-row gap-x-14 w-full">
                            <div class="w-6/12 mt-5">
                                <FundingRequirements v-if="isFundingView" :requirements="filteredStips" />
                                <ach-transaction-details v-else v-bind="transactionDetails" />
                            </div>
                            <div class="w-6/12 mt-5">
                                <Activity :entries="activities" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="flex justify-center items-center h-full">
                <empty-state
                    border-size="400"
                    icon-subtitle="Deal Data"
                    footer-text="Track deal status, required stipulations, and keep deal details for your records"
                    :vuetify-icon-props="{
                        icon: '$chartBoxOutline',
                        color: 'grey-darken-1',
                        size: '160',
                    }" />
            </div>
        </main>
    </div>
</template>

<script lang="ts" setup>
import { computed, ComputedRef, defineComponent, inject, onMounted, reactive, ref, Ref } from 'vue'
import dayjs from 'dayjs'
import AchTransactionDetails from '@/components/cards/ACHTransactionDetails.vue'
import AccountInfo, { Props as AccountInfoProps } from '@/components/cards/AccountInfo.vue'
import Disbursement from '@/components/partials/Disbursement.vue'
import FundingStats from '@/components/partials/FundingStats.vue'
import Activity, { Activity as ActivityModel } from '@/components/partials/Activity.vue'
import FundingRequirements from '@/components/partials/FundingRequirements.vue'
import VehicleCard from '@/components/partials/VehicleCard.vue'
import { Vehicle } from '@/models/Vehicle/Vehicle'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
import AccountList, { AccountListItem } from '@/components/partials/AccountList.vue'
import default_car_image from '@/assets/default_car_image.png'
import { useNotes } from '@/composables/accountNotes'
import { useLogger } from '@/composables/logger'
import { useContract } from '@/composables/contract'
import { useStipulations } from '@/composables/stipulations'
import { useGetTimeInStatus } from '@/composables/timeInStatus'
import { useGetTransactionDetails } from '@/composables/transactionDetails'
import pinpointClient from '@/clients/pinpointClient'
import { useRoute, useRouter } from 'vue-router'
import { sortAscending, sortDescending } from '@/helpers/sorters'
import {
    calculateDaysUntil,
    getCountdownExpiresDate,
    getDays,
    getFundingExpires,
    isInFunding,
} from '@/helpers/formatters'
import BookedStatus from '@/components/partials/BookedStatus.vue'
import { AccountStatuses } from '@/constants/accounts'
import { useDealerStore } from '@/stores/dealer/dealer'
import { Toast } from '@/models/toaster/toast'
import { storeToRefs } from 'pinia'
import EmptyState from '@/components/partials/EmptyState.vue'
import AdCampaign from '@/components/partials/AdCampaign.vue'
import MenuSort, { ISortMenu } from '@/components/MenuSort.vue'

dayjs.extend(timezone)
dayjs.extend(utc)
dayjs.extend(advanced)

defineComponent({
    name: 'Funding',
    components: { AccountInfo, Disbursement, VehicleCard, FundingStats, Activity },
})

const route = useRoute()
const router = useRouter()

//refs
const popToastLocal = inject('popToastLocal') as (toast: Toast) => {}
const dealerStore = useDealerStore()
const { statistics, dealerConfigs } = storeToRefs(dealerStore)
const { getStipulationAssociationsForAccount, filteredStips } = useStipulations()
const { whoReplied, accountNotes, getAccountNotes } = useNotes()
const { calculateTotalFees, getContractByAccountID } = useContract()
const { getFundedDate, transactionDetails } = useGetTransactionDetails()
const { getTimeInStatus, computedTimeInStatus } = useGetTimeInStatus()
const dealerID = route.params.dealer_id as string
const accountList: Ref<AccountListItem[]> = ref([])
const selectedAccountStatus: Ref<string> = ref(AccountStatuses.Funding)

const selectedVehicle: Ref<Vehicle> = ref({
    year: 0,
    make: '',
    model: '',
    trim: '',
    stockNo: '',
    vin: '',
    miles: 0,
    photoURL: default_car_image,
    used: false,
    favorited: false,
})

const sortBy: Ref<string> = ref('created_at asc')
const sortingFunctionMap = {
    'created_at asc': (a: AccountListItem, b: AccountListItem) => {
        return sortAscending<Number>(a.expiresInDays, b.expiresInDays)
    },
    'created_at desc': (a: AccountListItem, b: AccountListItem) => {
        return sortDescending<Number>(a.expiresInDays, b.expiresInDays)
    },
}
const isDataLoading: Ref<boolean> = ref(false)
const isLoadingAccountList: Ref<boolean> = ref(false)

const selectedAccount: ComputedRef<AccountListItem | undefined> = computed(() =>
    accountList.value.find((account) => account.selected),
)

const fundingOptions = [
    { type: 'subheader', title: 'Days To Expiration' },
    {
        prependTitle: 'Days To Expiration',
        value: 'created_at desc',
        title: 'High to Low',
    },
    {
        prependTitle: 'Days To Expiration',
        value: 'created_at asc',
        title: 'Low to High',
        subtitle: 'Default',
    },
]
const bookedOptions = [
    { type: 'subheader', title: 'Booked Date' },
    {
        prependTitle: 'Booked Date',
        value: 'status_updated_at desc',
        title: 'High to Low',
    },
    {
        prependTitle: 'Booked Date',
        value: 'status_updated_at asc',
        title: 'Low to High',
    },
]
const isFundingView: ComputedRef<boolean> = computed(() => isInFunding(selectedAccountStatus.value))

const computedOptions: ComputedRef<ISortMenu[]> = computed(() => {
    const viewOption: ISortMenu[] = isFundingView.value ? fundingOptions : bookedOptions
    return [
        { type: 'subheader', title: 'Approval Date' },
        {
            prependTitle: 'Approval Date',
            value: 'decisioned asc',
            title: 'Old to New',
        },
        {
            prependTitle: 'Approval Date',
            value: 'decisioned desc',
            title: 'New to Old',
            subtitle: isFundingView.value ? '' : 'Default',
        },
        { type: 'divider' },
        ...viewOption,
        { type: 'divider' },
        { type: 'subheader', title: 'General' },
        {
            prependTitle: 'General',
            value: 'account_id asc',
            title: 'Account ID',
        },
    ]
})

const computedExpiresAT = computed(() =>
    getFundingExpires(selectedAccount.value?.decisionedDate as Date, selectedAccount.value?.assignedDate),
)

const deal = reactive({
    DBamountFinanced: 0,
    DBamountFinancedMax: 0,
    DBacquisitionFees: 0,
    DBdealerFlat: 0,
    DBtotalDisbursement: 0,
    DBmaxAmountFinanced: '',
    DBdownPayment: '',
    DBavailableAdvance: '',
    DBapr: '0',
    DBterm: 0,
    DBpayment: '',
    DBmaxPayment: '',
    DBShortFundAmount: 0,
    DBspreadFee: 0,
    DBApr: 0,
    DBMaxApr: 0,
})
//computed props
const getAccountInfo: ComputedRef<AccountInfoProps> = computed(() => {
    type Result = {
        borrower: { firstName: string; lastName: string }
        coborrower?: { firstName: string; lastName: string }
        accountId: string
        applicationId: string
        countDownDate?: {
            date: string | number | Date | undefined
            countDown: number
        }
    }
    const result: Result = {
        borrower: {
            firstName: selectedAccount.value?.firstName ?? '',
            lastName: selectedAccount.value?.lastName ?? '',
        },
        accountId: String(selectedAccount.value?.accountId),
        applicationId: String(selectedAccount.value?.applicationId),
    }
    if (isFundingView.value) {
        result.countDownDate = getCountdownExpiresDate(
            selectedAccount.value?.decisionedDate!,
            selectedAccount.value?.assignedDate,
        )
    }

    if (selectedAccount.value?.coborrower) {
        result.coborrower = {
            firstName: selectedAccount.value?.coborrower.split(' ')[0],
            lastName: selectedAccount.value.coborrower.split(' ')[1] ?? '',
        }
    }
    return result
})

const info = computed(() => {
    if (selectedVehicle) {
        return {
            year: Number(selectedVehicle.value.year),
            make: selectedVehicle.value.make,
            model: selectedVehicle.value.model,
            trim: selectedVehicle.value.trim ?? '',
            stock: selectedVehicle.value.stockNo,
            vin: selectedVehicle.value.vin,
            miles: String(selectedVehicle.value.miles),
            carImage: selectedVehicle.value.photoURL,
        }
    } else {
        return {
            year: 0,
            make: '',
            model: '',
            trim: '',
            stock: '',
            vin: '',
            miles: '',
            carImage: '',
        }
    }
})

const activities: ComputedRef<ActivityModel[]> = computed(() => {
    return accountNotes.value.map((note) => {
        return {
            note: note.note,
            from: whoReplied(note) ?? '',
            time: dayjs(new Date(note.createdAt)).tz().format('dddd MMM DD YYYY hh:mmA'),
        }
    })
})

const computedNetDisbursement: ComputedRef<number> = computed(() => {
    return (
        deal.DBamountFinanced + deal.DBdealerFlat - deal.DBacquisitionFees - deal.DBShortFundAmount - deal.DBspreadFee
    )
})

// functions
function onAccountSelected(account: AccountListItem): void {
    loadPageData(account.accountId, account.primaryBorrowerId)
}

function getSortingFunction(sortKey: string): (a: AccountListItem, b: AccountListItem) => number {
    const sort = sortingFunctionMap[sortKey as keyof typeof sortingFunctionMap]
    return sort || (() => 0)
}

async function loadPageData(accountId: number, primaryBorrowerId: number): Promise<void> {
    isDataLoading.value = true
    try {
        await loadContractInfo(accountId)
        await getAccountNotes(accountId)
        await getTimeInStatus(accountId)
        if (!isFundingView.value) {
            await getFundedDate(String(accountId))
        } else {
            await getStipulationAssociationsForAccount(accountId, primaryBorrowerId) //primaryBorrowerId
        }
    } catch (e) {
        useLogger().log(e)
    } finally {
        isDataLoading.value = false
    }
}

async function loadContractInfo(accountId: number) {
    getContractByAccountID(accountId).then((contract) => {
        if (contract?.vehicle) {
            selectedVehicle.value.year = contract.vehicle.year
            selectedVehicle.value.make = contract.vehicle.make
            selectedVehicle.value.model = contract.vehicle.model
            selectedVehicle.value.trim = contract.vehicle.trim ?? ''
            selectedVehicle.value.vin = contract.vehicle.vin
            selectedVehicle.value.miles = contract.vehicle.odometer
            selectedVehicle.value.photoURL = default_car_image
        }
        if (contract?.disbursement) {
            deal.DBamountFinanced = contract.disbursement.amount_financed
            deal.DBamountFinancedMax = isFundingView.value ? contract.disbursement.amount_financed_max: 0
            deal.DBacquisitionFees = calculateTotalFees(contract)
            deal.DBdealerFlat = contract.disbursement.dealer_participation
            deal.DBShortFundAmount = contract.disbursement.short_fund_amount
            deal.DBspreadFee = contract.disbursement.spread_fee + contract.disbursement.additional_acquisition_fee
            deal.DBtotalDisbursement = computedNetDisbursement.value
            deal.DBApr = contract.disbursement.apr
            deal.DBMaxApr = contract.disbursement.max_apr
        }
    })
}

async function onSortChange(sort: string) {
    isLoadingAccountList.value = true
    try {
        sortBy.value = sort
        await setup()
    } catch (e) {
        useLogger().log(e)
    }
    isLoadingAccountList.value = false
}

async function getAccounts() {
    isDataLoading.value = true
    type QueryParams = {
        page: number
        pageLength: string
        statusIds: string
        startDate: string
        endDate: string
        sort: string
        typeIds?: string
    }
    let params: QueryParams = {
        page: 1,
        pageLength: '100',
        statusIds: selectedAccountStatus.value,
        startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        sort: sortBy.value,
        typeIds: '2',
    }

    if (!isFundingView.value) {
        delete params.typeIds
    }

    try {
        const response = await pinpointClient.get(`/accounts/all/dealer/${dealerID}`, {
            params,
        })

        accountList.value = response.data.Accounts.map((account: any) => {
            let assignedDate: Date | undefined
            if (isFundingView.value) {
                assignedDate = account.funder_assigned_date
                    ? dayjs.utc(account.funder_assigned_date).toDate()
                    : undefined
            }

            return {
                firstName: account.primary_borrower_first_name,
                lastName: account.primary_borrower_last_name,
                coborrower: account.co_borrower_name,
                accountId: Number(account.account_id),
                applicationId: Number(account.application_id),
                statusDate: dayjs.utc(account.status_updated_at).toDate(),
                decisionedDate: dayjs.utc(account.decisioned).toDate(),
                assignedDate: account.funder_assigned_date,
                selected: false,
                expiresInDays: calculateDaysUntil(getDays(dayjs.utc(account.decisioned).toDate(), assignedDate)),
                statusId: account.status_id,
                primaryBorrowerId: account.primary_borrower_id,
            }
        })
            .filter((account: AccountListItem) => {
                return Number(account.expiresInDays) >= 0
            })
            .sort(getSortingFunction(sortBy.value))
    } catch {
        isDataLoading.value = false
        accountList.value = []
        popToastLocal({
            title: 'No Applications Found',
            message: `There are no applications for this status.`,
            timer: 5000,
            location: 'top-center',
        })
    }
    isDataLoading.value = false
}

async function setup() {
    if (!dealerStore.getStatistics.isReady) {
        await dealerStore.getStatistics.execute()
    }
    await getAccounts()
    if (accountList.value.length) {
        if (route.params.account_id) {
            let selectedAccount = accountList.value.find((a) => a.accountId.toString() == route.params.account_id)
            if (!selectedAccount) {
                selectedAccount = accountList.value[0]
            }
            if (route.params.account_id != selectedAccount.accountId.toString()) {
                router.push({
                    name: 'Accounts',
                    params: { dealer_id: route.params.dealer_id, account_id: accountList.value[0].accountId },
                })
            }
            selectedAccount.selected = true
            await loadPageData(selectedAccount.accountId, selectedAccount.primaryBorrowerId)
        } else {
            router.push({
                name: 'Accounts',
                params: { dealer_id: route.params.dealer_id, account_id: accountList.value[0].accountId },
            })
            accountList.value[0].selected = true
            loadPageData(accountList.value[0].accountId, accountList.value[0].primaryBorrowerId)
        }
    }
}

async function onAccountStatusChange(status: string) {
    isLoadingAccountList.value = true
    selectedAccountStatus.value = status
    sortBy.value = isFundingView.value ? 'created_at asc' : 'decisioned desc'
    await setup()
    isLoadingAccountList.value = false
}

//life cycle hooks
onMounted(async () => {
    if (route.params.account_id) {
        const response = await pinpointClient.get(`/accounts/get/${route.params.account_id}`)
        if (!isInFunding(response.data.account.status.id)) {
            await onAccountStatusChange(AccountStatuses.Booked)
            return
        }
    }
    await setup()
})
</script>
