<template>
    <div class="h-full">
        <div class="w-full py-6">
            <ad-campaign variant="horizontal" />
        </div>
        <v-card flat>
            <v-toolbar>
                <v-toolbar-title class="!text-2xl">Setup your dealership</v-toolbar-title>
            </v-toolbar>
            <div class="flex flex-row">
                <v-tabs
                    v-model="tab"
                    direction="vertical"
                    variant="flat"
                    density="compact"
                    bg-color="slate-50"
                    selected-class="bg-white border-none"
                    class="text-black gap-1">
                    <v-tab value="banking" class="py-10">
                        <div class="flex flex-col gap-4 px-2 pb-2 w-100">
                            <div class="flex flex-row justify-center items-center gap-4 text-none">
                                <v-icon start color="green" size="29" icon="$cashMultiple"></v-icon>
                                <h2 class="text-slate-900 text-sm font-semibold">Banking & funding information</h2>
                            </div>
                        </div>
                    </v-tab>
                    <v-tab value="dealership" class="py-10">
                        <div class="flex flex-col gap-4 px-2 pb-2 w-100">
                            <div class="flex flex-row justify-center items-center gap-4 text-none">
                                <v-icon start color="red-lighten-2" size="29" icon="$car"></v-icon>
                                <h2 class="text-slate-900 text-sm font-semibold">Edit your dealership information</h2>
                            </div>
                        </div>
                    </v-tab>
                    <v-tab value="contacts" class="py-10" :disabled="!cShowDealerContacts">
                        <div class="flex flex-col gap-4 px-2 pb-2 w-100">
                            <div class="flex flex-row justify-center items-center gap-4 text-none">
                                <v-icon start color="blue-darken-4" size="29" icon="$contacts"></v-icon>
                                <h2 class="text-slate-900 text-sm font-semibold">Dealership contact list</h2>
                            </div>
                        </div>
                    </v-tab>
                </v-tabs>
                <v-window v-model="tab" class="mx-6 w-full">
                    <v-window-item value="banking">
                        <v-form v-model="isFormValid">
                            <v-container class="mx-5">
                                <v-row align="start" justify="center">
                                    <v-col>
                                        <v-card class="my-4" min-height="665" flat color="bg-slate-50">
                                            <div class="flex flex-col px-6">
                                                <v-toolbar class="!bg-slate-50">
                                                    <v-toolbar-title class="!font-medium !text-normal border-b py-4"
                                                        >Banking Information</v-toolbar-title
                                                    >
                                                </v-toolbar>
                                                <v-card-text class="flex flex-col gap-4 bg-slate-50">
                                                    <v-card
                                                        class="mb-4"
                                                        v-if="myBankAccount.status === BANK_ACCOUNT_STATUS.VERIFIED">
                                                        <v-card-text>
                                                            <v-icon
                                                                color="green mr-2"
                                                                size="24"
                                                                icon="$checkCircleOutline">
                                                            </v-icon>
                                                            <span class="text-green-darken-2 text-body-1 !font-semibold"
                                                                >Currently verified and active account</span
                                                            >
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-text-field
                                                        v-model="myBankAccount.name"
                                                        data-testid="dealer-bank-name"
                                                        :disabled="cIsBankingAccountPending"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        color="!text-normal"
                                                        :rules="[validators.required]"
                                                        label="Bank name"></v-text-field>
                                                    <v-text-field
                                                        v-model="myBankAccount.routingNumber"
                                                        data-testid="dealer-routing-number"
                                                        :disabled="cIsBankingAccountPending"
                                                        :rules="[
                                                            validators.required,
                                                            validators.minLength(9, 'Min 9 digits'),
                                                            validators.maxLength(9),
                                                        ]"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        :maxlength="9"
                                                        :min-length="9"
                                                        @update:model-value="
                                                            myBankAccount.routingNumber = $event.replace(/\D/g, '')
                                                        "
                                                        label="Routing Number"></v-text-field>
                                                    <v-text-field
                                                        v-if="!cIsBankingAccountPending"
                                                        data-testid="dealer-confirm-routing-number"
                                                        v-model="myBankAccount.routingNumberConfirm"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        :rules="[
                                                            validators.required,
                                                            validators.match(
                                                                myBankAccount.routingNumber,
                                                                'Routing numbers do not match',
                                                            ),
                                                            validators.minLength(9, 'Min 9 digits'),
                                                            validators.maxLength(9),
                                                        ]"
                                                        :maxlength="9"
                                                        @update:model-value="
                                                            myBankAccount.routingNumberConfirm = $event.replace(
                                                                /\D/g,
                                                                '',
                                                            )
                                                        "
                                                        label="Confirm Routing Number"></v-text-field>
                                                    <v-text-field
                                                        v-model="myBankAccount.accountNumber"
                                                        data-testid="dealer-bank-account-number"
                                                        :disabled="cIsBankingAccountPending"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        :rules="[
                                                            validators.required,
                                                            validators.minLength(8, 'Min 8 digits'),
                                                            validators.maxLength(17),
                                                        ]"
                                                        :maxlength="17"
                                                        label="Account Number"
                                                        @update:model-value="
                                                            myBankAccount.accountNumber = $event.replace(/\D/g, '')
                                                        " />
                                                    <v-text-field
                                                        v-if="!cIsBankingAccountPending"
                                                        v-model="myBankAccount.accountNumberConfirm"
                                                        data-testid="dealer-bank-confirm-account-number"
                                                        bg-color="white"
                                                        :maxlength="17"
                                                        :rules="[
                                                            validators.required,
                                                            validators.match(
                                                                myBankAccount.accountNumber,
                                                                'Account numbers do not match',
                                                            ),
                                                            validators.minLength(8, 'Min 8 digits'),
                                                            validators.maxLength(17),
                                                        ]"
                                                        label="Confirm Account Number"
                                                        @update:model-value="
                                                            myBankAccount.accountNumberConfirm = $event.replace(
                                                                /\D/g,
                                                                '',
                                                            )
                                                        "></v-text-field>
                                                    <v-select
                                                        v-if="cIsBankingAccountPending"
                                                        v-model="myBankAccount.paymentOptionId"
                                                        :disabled="cIsBankingAccountPending"
                                                        :bg-color="cIsBankingAccountPending ? '' : 'white'"
                                                        label="Choose payment frequency option"
                                                        :items="[
                                                            { title: 'ACH', value: 'ach' },
                                                            // { title: 'Wire transfer', value: 'wire', disabled: true },
                                                            // { title: 'FedNow', value: 'fednow', disabled: true },
                                                        ]" />
                                                    <p
                                                        class="text-xs text-normal"
                                                        v-if="myBankAccount.status != BANK_ACCOUNT_STATUS.VERIFIED">
                                                        *In order to verify your bank account we will be sending a
                                                        micro-transaction to your account. The amount will be between
                                                        $0.01 and $0.15 and will say "Arivo Acceptance" in the
                                                        description. Please follow the prompts you will receive after
                                                        finalizing your account setup. Keep in mind that the
                                                        Micro-Transaction can take up to 48 hours to fully complete.
                                                    </p>
                                                </v-card-text>
                                            </div>
                                        </v-card>
                                    </v-col>
                                    <v-col>
                                        <!-- PAYMENT OPTION -->
                                        <v-card class="my-4" flat v-if="!myBankAccount.status">
                                            <v-toolbar class="!bg-slate-50 mb-10 !border-b">
                                                <v-toolbar-title class="!font-medium py-4 !text-normal"
                                                    >Payment options</v-toolbar-title
                                                >
                                            </v-toolbar>
                                            <v-radio-group
                                                v-model="myBankAccount.paymentOptionId"
                                                :rules="[validators.required]"
                                                direction="horizontal">
                                                <div class="flex flex-col gap-10">
                                                    <v-card color="#e0f2f1b3" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center justify-between px-4 py-10 text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_basic.svg" alt="basic" />
                                                                <span class="text-xl font-medium"
                                                                    >ACH Payment twice a day</span
                                                                >
                                                            </div>
                                                            <span class="self"><v-radio value="ach" /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card color="#ede7f6b3" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center px-4 py-10 justify-between text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_fast.svg" alt="fast" />
                                                                <span class="text-xl font-medium"
                                                                    >Wire transfer paid out on the hour every hour</span
                                                                >
                                                            </div>
                                                            <span
                                                                ><v-radio value="wire" class="" :disabled="true"
                                                            /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                    <v-card color="#ffe7f6b3" elevation="0">
                                                        <v-card-text
                                                            class="flex flex-row gap-8 items-center px-4 py-10 justify-between text-black">
                                                            <div class="flex flex-row items-center gap-8">
                                                                <img src="@/assets/payment_fastest.svg" alt="fastest" />
                                                                <span class="text-xl font-medium"
                                                                    >FedNow paid out after each funded application</span
                                                                >
                                                            </div>
                                                            <span
                                                                ><v-radio value="fednow" class="" :disabled="true"
                                                            /></span>
                                                        </v-card-text>
                                                    </v-card>
                                                </div>
                                            </v-radio-group>
                                            <v-row>
                                                <v-col class="flex gap-4 justify-end">
                                                    <v-btn
                                                        @click="showConfirmBankInfo = false"
                                                        color="blue"
                                                        class="text-none"
                                                        variant="text"
                                                        >Cancel</v-btn
                                                    >
                                                    <v-btn
                                                        data-testid="dealer-finalize-setup-bank-btn"
                                                        color="primary"
                                                        class="text-none"
                                                        :disabled="!isFormValid"
                                                        @click="showConfirmBankInfo = true"
                                                        >Finalize setup</v-btn
                                                    >
                                                </v-col>
                                            </v-row>
                                        </v-card>

                                        <!-- PENDING -->
                                        <v-card class="my-4 mx-2" flat v-if="cIsBankingAccountPending">
                                            <verify-account-bank
                                                v-if="!allowUpdateAccount"
                                                :bank-name="myBankAccount.name"
                                                :digits="myBankAccount.accountNumber"
                                                :date="cTransactionDate"
                                                :dealer-id="dealerId"
                                                :row-id="myBankAccount.id"
                                                @account-verified="onAccountVerified" />
                                            <resend-micro-deposits
                                                :is-setup-page="false"
                                                v-if="!allowUpdateAccount"
                                                @update-bank-information="onCorrectBankInformation"
                                                :account-row-id="Number(myBankAccount.id)" />

                                            <verify-account-form
                                                :routing-number="bankAccountToEdit.routingNumber"
                                                :account-number="bankAccountToEdit.accountNumber"
                                                :bank-name="bankAccountToEdit.name"
                                                :payment-option="bankAccountToEdit.paymentOptionId"
                                                :bank-account-status="bankAccount.status"
                                                @cancel="allowUpdateAccount = false"
                                                @updated-bank-information="onUpdateAccountInformation"
                                                v-if="allowUpdateAccount" />
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-window-item>
                    <v-window-item value="dealership">
                        <dealer-information-form :is-setup-page="false" />
                    </v-window-item>
                    <v-window-item value="contacts">
                        <dealer-contacts :titles="contactTitles" :contacts="contacts" />
                    </v-window-item>
                </v-window>
            </div>
        </v-card>
        <!-- modal -->
        <V-dialog v-model="openDialog" max-width="645">
            <v-card class="!py-4 !px-6">
                <v-card-title data-test-dialog-title class="font-inter !text-base !font-semibold">
                    Ready to verify your account?
                </v-card-title>
                <v-card-text class="text-[#00000099] !text-sm !font-inter"
                    >You will receive 2 micro-deposits in the account that you entered. You will be asked to enter the
                    exact amounts of the deposits on the next screen to verify your account</v-card-text
                >
                <v-card-actions class="justify-end">
                    <v-btn color="primary" @click="openDialog = false" class="text-none">Cancel</v-btn>
                    <v-btn
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        variant="flat"
                        class="text-none"
                        @click="save(myBankAccount)"
                        >Continue</v-btn
                    >
                </v-card-actions>
            </v-card>
        </V-dialog>

        <V-dialog v-model="showConfirmBankInfo" max-width="645" data-testid="dealer-confirm-bank-info-dialog">
            <v-card class="!py-4 !px-6">
                <v-card-title data-test-dialog-title class="font-inter !text-base !font-semibold">
                    Review your bank information
                </v-card-title>
                <v-card-text class="text-[#00000099] !text-sm !font-inter">
                    <p class="mb-4">Please confirm your bank details before proceeding:</p>

                    <p>Bank Name: {{ myBankAccount.name }}</p>
                    <p>Routing number: {{ myBankAccount.routingNumber }}</p>
                    <p>Account number: {{ myBankAccount.accountNumber }}</p>
                </v-card-text>
                <v-card-actions class="justify-end">
                    <v-btn
                        data-testid="dealer-confirm-bank-info-cancel-btn"
                        color="primary"
                        @click="confirmBankInfo(false)"
                        class="text-none"
                        >Cancel</v-btn
                    >
                    <v-btn
                        data-testid="dealer-confirm-bank-info-confirm-btn"
                        color="primary"
                        :loading="loading"
                        :disabled="loading"
                        variant="flat"
                        class="text-none"
                        @click="confirmBankInfo(true)"
                        >Confirm</v-btn
                    >
                </v-card-actions>
            </v-card>
        </V-dialog>
    </div>
</template>
<script lang="ts" setup>
import DealerInformationForm from '@/components/DealerInformationForm.vue'
import ResendMicroDeposits from '@/components/cards/ResendMicroDeposits.vue'
import VerifyAccountBank from '@/components/cards/VerifyAccountBank.vue'
import VerifyAccountForm from '@/components/cards/VerifyAccountForm.vue'
import DealerContacts from '@/components/partials/DealerContacts.vue'
import AdCampaign from '@/components/partials/AdCampaign.vue'
import { validators } from '@/helpers/validators'
import { BANK_ACCOUNT_STATUS, BankAccount } from '@/models/Dealer/BankAccount'
import { useDealerStore } from '@/stores/dealer/dealer'
import dayjs from 'dayjs'
import { ComputedRef, Ref, computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { onMounted } from 'vue'

defineComponent({
    name: 'Dealer',
})

onMounted(async () => {
    await getBankInfo()
})

const route = useRoute()
const router = useRouter()
const dealerStore = useDealerStore()
const { bankAccount, contactTitles, contacts } = storeToRefs(dealerStore)

const routeDealerId = route?.params?.dealer_id
const dealerId: ComputedRef<number> = computed(() => Number(routeDealerId))
const cShowDealerContacts = computed(() => import.meta.env.VITE_FEATURE_MANAGE_DEALER_CONTACTS === 'true')

const tab = ref('banking')
const allowUpdateAccount: Ref<boolean> = ref(false)
const isFormValid: Ref<boolean> = ref(false)

const cIsBankingAccountPending: ComputedRef<boolean> = computed(
    () =>
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.PENDING ||
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.SENT ||
        myBankAccount.value.status === BANK_ACCOUNT_STATUS.VERIFIED,
)
const cTransactionDate: ComputedRef<string | undefined> = computed(() =>
    myBankAccount.value.microTransactionSent
        ? dayjs(myBankAccount.value.microTransactionSent).format('MM/DD/YYYY')
        : undefined,
)

const myBankAccount: Ref<BankAccount> = ref({
    name: '',
    routingNumber: '',
    accountNumber: '',
    routingNumberConfirm: '',
    accountNumberConfirm: '',
    paymentOptionId: 'ach',
    status: '',
})

const bankAccountToEdit: Ref<BankAccount> = ref({
    name: '',
    routingNumber: '',
    accountNumber: '',
    routingNumberConfirm: '',
    accountNumberConfirm: '',
    paymentOptionId: 'ach',
    status: '',
})

const openDialog: Ref<boolean> = ref(false)
const showConfirmBankInfo: Ref<boolean> = ref(false)
const loading: Ref<boolean> = ref(false)

async function getBankInfo(): Promise<void> {
    await dealerStore.getBankInformation(undefined)

    myBankAccount.value.accountNumber =
        bankAccount.value.accountNumber.length > 0 ? bankAccount.value?.accountNumber.padStart(12, '*') : ''
    myBankAccount.value.routingNumber = bankAccount.value.routingNumber
    myBankAccount.value.name = bankAccount.value.name
    myBankAccount.value.status = bankAccount.value.status
    myBankAccount.value.id = bankAccount.value.id
    myBankAccount.value.microTransactionSent = bankAccount.value?.microTransactionSent
    myBankAccount.value.paymentOptionId = bankAccount.value.paymentOptionId || 'ach'

    if (bankAccount.value.status == BANK_ACCOUNT_STATUS.VERIFIED) {
        allowUpdateAccount.value = true
    }
}

async function onUpdateAccountInformation(account: BankAccount): Promise<void> {
    await getBankInfo()
    allowUpdateAccount.value = false
}

function onAccountVerified(): void {
    bankAccountToEdit.value.name = ''
    bankAccountToEdit.value.routingNumber = ''
    bankAccountToEdit.value.accountNumber = ''
    bankAccountToEdit.value.routingNumberConfirm = ''
    bankAccountToEdit.value.accountNumberConfirm = ''
    bankAccountToEdit.value.paymentOptionId = 'ach'
    bankAccountToEdit.value.status = BANK_ACCOUNT_STATUS.VERIFIED

    getBankInfo()
}

function onCorrectBankInformation(): void {
    allowUpdateAccount.value = true
    bankAccountToEdit.value = myBankAccount.value
}

async function save(account: BankAccount): Promise<void> {
    loading.value = true
    account.status = BANK_ACCOUNT_STATUS.PENDING

    dealerStore
        .saveBankingInfo(account)
        .then(() => {
            openDialog.value = false
            loading.value = false
            getBankInfo()
        })
        .catch(() => {
            loading.value = false
        })
}

function confirmBankInfo(confirm: boolean): void {
    if (confirm) {
        openDialog.value = true
        showConfirmBankInfo.value = false
        return
    }

    showConfirmBankInfo.value = false
    openDialog.value = false
}

onBeforeMount(async () => {
    await getBankInfo()
    await dealerStore.getContactTitles.execute()
    await dealerStore.getDealerContacts.execute()
    if (!dealerStore.isPrivileged) {
        router.push({ name: 'EmailAuthentication', params: { dealer_id: route.params.dealer_id } })
    }
})
</script>

<style>
.v-tab.v-btn.v-btn--density-default {
    height: auto;
}

.v-text-field input {
    color: #1e293b !important;
}

.text-black {
    color: #1e293b !important;
}
</style>
